<script setup lang="ts">
import { z } from 'zod'
import { watchDebounced } from '@vueuse/core'
import type { Tables } from '@/types/database.types.ts'

const route = useRouter()
const supabase = useSupabaseClient()
const config = useRuntimeConfig()
const { currentUser } = useAuth()
const nuxtApp = useNuxtApp()
const stripe = await nuxtApp.$stripe

const emailInput = ref<HTMLInputElement | null>(null)

let checkout: object | null = null
const paymentClientKey = ref(null)
const loadingPaymentForm = ref(true)

const props = defineProps<{
  creator: Tables<'profiles'>;
  tier: string;
  selectedPriceId: string;
}>()

const createCheckoutSession = async () => {
  const { data, error } = await supabase.functions.invoke('stripe-payments/create-checkout-session', {
    method: 'POST',
    body: {
      type: props.tier,
      creatorId: props.creator.id || null,
      priceId: props.selectedPriceId,
      redirectUrl: config.public.baseURL + route.currentRoute.value.fullPath + '?payment=success',
    }
  })
  if (error) showError(error)
  paymentClientKey.value = data.clientSecret
}


const stripeOptions = {
  appearance: {
    theme: 'stripe',
    // customFontSource: 'https://fonts.googleapis.com/css2?family=Uncut+Sans:wght@400;500;600;700&display=swap',
    variables: {
      fontFamily: 'Uncut Sans, sans-serif',
      colorPrimary: '#f33f5e',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      spacingUnit: '4px',
      fontSizeBase: '16px',
    },
    // https://docs.stripe.com/payments/checkout/customization/appearance?payment-ui=embedded-components
    rules: {
      '.Label': {
        fontWeight: '600',
        fontSize: '16px',
        color: '#475569',
        margin: '0 0 0.5rem 0'
      },
      '.Input': {
        fontWeight: '400',
        fontSize: '16px',
        color: '#64748a',
        border: '1px solid #f0f4f8',
        boxShadow: '0 0 0 1px #e1e7ef, 0 2px 2px 0 #f0f4f8',
      },
      '.Input--invalid': {
        border: '1px solid #e01d48',
      },
      '.Error': {
        color: '#e01d48',
        fontWeight: '400',
        fontSize: '0.9rem',
      }
    }
  }
}
const { trackEvent } = useMetrics()
const paymentPending = ref(false)
const checkoutIsLoading = ref(true)
onMounted(async () => {
  await createCheckoutSession()
  checkout = await stripe.initCheckout({
    clientSecret: paymentClientKey.value,
    elementsOptions: stripeOptions,
  })

  const paymentElement = checkout.createElement('payment', {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          line1: 'never',
          line2: 'never',
          city: 'never',
          state: 'never',
          postalCode: 'auto',
          country: 'auto',
        }
      }
    }
  })
  paymentElement.mount('#payment-element')

  const button = document.getElementById('pay-button')
  const errors = document.getElementById('confirm-errors')
  // todo handle error text
  if (!button) return
  button.addEventListener('click', async () => {
    paymentPending.value = true
    if (!currentUser.value) {
      emailInput.value.validate()
      await checkout.updateEmail(userEmail.value)
    } else {
      await checkout.updateEmail(currentUser.value?.user.email)
    }
    checkout.confirm().then((result) => {
      if (result.type === 'error') {
        errors.textContent = result.error.message
      } else {
        trackEvent('subscribed_paid', {
          subscription_tier: props.tier
        })
      }
      paymentPending.value = false
    })
  })
  checkoutIsLoading.value = false
})

// Handle Auth Flow
const userEmail = ref<string | null>(null)
const loginIsVisible = ref(false)
const stripeForm = ref<HTMLFormElement | null>(null)
const emailSchema = z
  .string({ message: 'Your Email is required' })
  .email({ message: 'Please enter a valid email address' })

watchDebounced(userEmail, () => {
  if (userEmail.value && emailSchema.safeParse(userEmail.value).success) {
    console.log('userEmail', userEmail.value)
  }
}, {
  debounce: 500,
  maxWait: 1000
})

watch(loginIsVisible, () => {
  if (loginIsVisible.value) {
    stripeForm.value.style.opacity = '0'
  } else {
    stripeForm.value.style.opacity = '1'
  }
})
</script>

<template>
  <div>
    <h4 v-if="!loadingPaymentForm">Support <span>@{{ creator.mappr_handle }}<span/></span></h4>
    <div v-if="checkoutIsLoading" class="loading">
      <MPLoader inline label="Loading checkout" />
    </div>
    <div v-if="!currentUser && !loginIsVisible && !checkoutIsLoading" class="user-info">
      <MPInput
        ref="emailInput"
        v-model="userEmail"
        name="email"
        label="Create an account"
        type="email"
        placeholder="Enter your Email"
        :validation="emailSchema"
      />
      <small>Already registered? <a href="#" @click="loginIsVisible = true">Click here to login</a></small>
    </div>
    <div v-if="loginIsVisible">
      <a href="#" class="back-to-payment-button" @click="loginIsVisible = false">
        <i class="ri-arrow-left-line"/>&nbsp;Back to payment
      </a>
      <br>
      <MPAuth
        title="Login"
        summary="Login to continue, or click back to payment to create an account"
        @on-auth-success="loginIsVisible = true"
      />
    </div>
    <form id="payment-form" ref="stripeForm" @submit.prevent>
      <div id="payment-element"/>
      <div id="confirm-errors"/>
      <MPButton id="pay-button" label="Pay & Subscribe" version="primary" :disabled="paymentPending" :is-loading="paymentPending"/>
    </form>
  </div>
</template>

<style lang="postcss" scoped>

.user-info,
#payment-form {
  text-align: left;
  display: grid;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1.5rem;
  max-width: 26rem;
  margin: 0 auto 1rem;
}

small {
  color: var(--color-slate-400);
  line-height: 1.5;
  a {
    color: var(--color-indigo-400);
  }
}

.back-to-payment-button {
  display: block;
  background-color: var(--color-slate-100);
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
#confirm-errors {
  color: var(--color-rose-600);
  margin-top: 0.5rem;
  margin: 0 auto;
  border-radius: 0.5rem;
}
.checkout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#payment-element {
  margin-bottom: 1.5rem;
}
span {
  color: var(--color-rose-500);
}
.loading {
  color: var(--color-slate-400);
  padding-top: 3rem;
  position: absolute;
  top: 3rem;
  i {
    position: absolute;
    font-size: 1.5rem;
    top: 1rem;
    left: 50%;
    width: 1.5re;
    height: 1.5re;
    margin: -0.75rem 0 0 -0.75rem;
    animation: spin 4s linear infinite;
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
